import request from '../request'

//采购申请列表
export const purchase_index_request = p => {
    return request({
        method:'GET',
        url:'purchase/index',
        params: p
    })
}

//发起采购申请
export const purchase_add_request = d => {
    return request({
        method:'POST',
        url:'purchase/add',
        data: d
    })
}

//删除采购申请
export const purchase_del_request = id => {
    return request({
        method:'DELETE',
        url:'purchase/del',
        data: {
            id: id
        }
    })
}

//修改采购申请
export const purchase_edit_request = data => {
    return request({
        method:'PUT',
        url:'purchase/edit',
        data
    })
}

//获取采购申请详情
export const purchase_details_request = id => {
    return request({
        method:'GET',
        url:'purchase/details',
        params: {
            id
        }
    })
}

// 提交采购申请
export const purchase_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'purchase/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看采购申请审批记录
export const purchase_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'purchase/sel_approval',
        params: {
            id
        }
    })
}

// 审批采购申请
export const purchase_approval_request = d => {
    return request({
        method:'PUT',
        url:'purchase/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other
        }
    })
}

// 撤回采购申请
export const purchase_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'purchase/withdraw',
        data: {
            id: d.id
        }
    })
}