import request from '../request'

//加班申请列表
export const over_time_index_request = p => {
    return request({
        method:'GET',
        url:'over_time/index',
        params: p
    })
}

//发起加班申请
export const over_time_add_request = d => {
    return request({
        method:'POST',
        url:'over_time/add',
        data: d
    })
}

//删除加班申请
export const over_time_del_request = id => {
    return request({
        method:'DELETE',
        url:'over_time/del',
        data: {
            id: id
        }
    })
}

//修改加班申请
export const over_time_edit_request = data => {
    return request({
        method:'PUT',
        url:'over_time/edit',
        data
    })
}

//获取加班申请详情
export const over_time_details_request = id => {
    return request({
        method:'GET',
        url:'over_time/details',
        params: {
            id
        }
    })
}

// 提交加班申请
export const over_time_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'over_time/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看加班申请审批记录
export const over_time_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'over_time/sel_approval',
        params: {
            id
        }
    })
}

// 审批加班申请
export const over_time_approval_request = d => {
    return request({
        method:'PUT',
        url:'over_time/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other
        }
    })
}

// 撤回加班申请
export const over_time_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'over_time/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印加班申请
export const over_time_print_request = id => {
    return request({
        method:'PUT',
        url:'over_time/print',
        data: {
            id
        }
    })
}