<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset v-for="(item,index) in data" :key="index">
            <div v-if="item.start_time">
                <p style="font-size: 0.4rem;text-align: center;margin-top: 0.2rem"><van-icon name="share-o" /><span>审批发起</span></p>
                <van-cell>
                    <template #title>
                        <p style="margin-left: 0.2rem">发起人</p>
                        <p style="margin-left: 0.2rem">发起时间</p>
                    </template>
                    <template #icon>
                        <van-image width="2rem" height="2rem" :src="$store.state.baseURL + item.head_portrait" />
                    </template>
                    <template #right-icon>
                        <div>
                            <p style="text-align: right">{{item.start_staff_name}}</p>
                            <p style="text-align: right">{{item.start_time}}</p>
                        </div>
                    </template>
                </van-cell>
                <van-cell v-if="item.note" title="申请原因" :value="item.note" />
            </div>
            <div v-else-if="(index < active) && (item.staff_id !==0) && (item.staff_id !== -1)">
                <p v-if="item.opinion === '同意'" style="font-size: 0.4rem;text-align: center;margin-top: 0.2rem;color: #00BB00;"><van-icon name="passed" /><span>审批通过</span></p>
                <p v-if="item.opinion === '拒绝'" style="font-size: 0.4rem;text-align: center;margin-top: 0.2rem;color: #EA0000;"><van-icon name="close" /><span>审批驳回</span></p>
                <van-cell>
                    <template #title>
                        <p style="margin-left: 0.2rem">审批人</p>
                        <p style="margin-left: 0.2rem">审批时间</p>
                        <p style="margin-left: 0.2rem">审批意见</p>
                    </template>
                    <template #icon>
                        <van-image width="2rem" height="2rem" :src="$store.state.baseURL + item.head_portrait" />
                    </template>
                    <template #right-icon>
                        <div>
                            <p style="text-align: right">{{item.staff_name}}</p>
                            <p style="text-align: right">{{item.time}}</p>
                            <p v-if="item.opinion === '同意'" style="text-align: right;font-weight: bold;color: #00BB00;">{{item.opinion}}</p>
                            <p v-if="item.opinion === '拒绝'" style="text-align: right;font-weight: bold;color: #EA0000;">{{item.opinion}}</p>
                        </div>
                    </template>
                </van-cell>
                <van-cell v-if="item.note" title="审批建议" :value="item.note" />
                <van-cell v-if="item.other" title="附件">
                    <template #label>
                        <uploader-component
                            :farr="item.other"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
            </div>
            <div v-else-if="(index < active) && (item.staff_id ===0)" class="van-contact-card">
                <van-divider style="color: #000000;font-weight: bold;">
                    <span>审批结果：【</span>
                    <span :class="col_content_class(data[index-1])">{{data[index-1] | zidongtongguo}}</span>
                    <span>】</span>
                </van-divider>
            </div>
            <div v-else-if="(index < active) && (item.staff_id ===-1)">
                <p style="font-size: 0.4rem;text-align: center;margin-top: 0.2rem;color: #B15BFF"><van-icon name="revoke" /><span>审批撤回</span></p>
                <van-cell>
                    <template #title>
                        <p style="margin-left: 0.2rem">撤回人</p>
                        <p style="margin-left: 0.2rem">撤回时间</p>
                    </template>
                    <template #icon>
                        <van-image width="2rem" height="2rem" :src="withdraw_staff_head_portrait" />
                    </template>
                    <template #right-icon>
                        <div>
                            <p style="text-align: right">{{data | withdraw_staff_name}}</p>
                            <p style="text-align: right">{{item.time}}</p>
                        </div>
                    </template>
                </van-cell>
            </div>
            <div v-else-if="index === active">
                <p style="font-size: 0.4rem;text-align: center;margin-top: 0.2rem;color: #2828FF"><van-icon name="flag-o" /><span>审批中</span></p>
                <van-cell>
                    <template #title>
                        <p style="margin-left: 0.2rem">审批人</p>
                        <p style="margin-left: 0.2rem">审批状态</p>
                    </template>
                    <template #icon>
                        <van-image width="2rem" height="2rem" :src="$store.state.baseURL + item.head_portrait" />
                    </template>
                    <template #right-icon>
                        <div>
                            <p style="text-align: right">{{item.staff_name}}</p>
                            <p style="text-align: right;color: #2828FF">审批中</p>
                        </div>
                    </template>
                </van-cell>
            </div>
            <div v-else>
                <p style="font-size: 0.4rem;text-align: center;margin-top: 0.2rem;color: #C0C4CC"><van-icon name="clock-o" /><span>待审批</span></p>
                <van-cell>
                    <template #title>
                        <p style="margin-left: 0.2rem">审批人</p>
                        <p style="margin-left: 0.2rem">审批状态</p>
                    </template>
                    <template #icon>
                        <van-image width="2rem" height="2rem" :src="$store.state.baseURL + item.head_portrait" />
                    </template>
                    <template #right-icon>
                        <div>
                            <p style="text-align: right">{{item.staff_name}}</p>
                            <p style="text-align: right;color: #C0C4CC">待审批</p>
                        </div>
                    </template>
                </van-cell>
            </div>
        </van-cell-group>
        <div class="sub_button" v-if="ApprovalAuth">
            <van-button round block type="info" @click="show=true">审 批</van-button>
        </div>
        <van-dialog v-model:show="show" title="审批" show-cancel-button @close="close_dialog" @confirm="submitApproval">
            <van-radio-group v-model="form.opinion" direction="horizontal">
                <van-radio name="同意" checked-color="#00BB00" style="margin:0.5rem 1.5rem;">同意</van-radio>
                <van-radio name="拒绝" checked-color="#CE0000">拒绝</van-radio>
            </van-radio-group>
            <van-field
                type="textarea"
                v-model="form.note"
                name="note"
                label="审批建议"
                placeholder="请填写审批建议"
                :rules="[{ required: true, message: '请填写审批建议' }]"
            />
            <van-cell title="上传附件">
                <template #label>
                    <uploader-component
                        :farr="form.other"
                        url="user/approval_upload"
                        @update_img="update_img"
                    ></uploader-component>
                </template>
            </van-cell>
        </van-dialog>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { purchase_sel_approval_request,purchase_approval_request } from '@/network/finance/Purchase.js' //采购
import { entertain_sel_approval_request,entertain_approval_request } from '@/network/finance/Entertain.js'  //招待
import { travel_sel_approval_request,travel_approval_request } from '@/network/finance/Travel.js'   //差旅
import { petty_sel_approval_request,petty_approval_request } from '@/network/finance/Petty.js'  //备用金
import { reimbursement_sel_approval_request,reimbursement_approval_request } from '@/network/finance/Reimbursement.js'  //报销
import { business_payment_sel_approval_request,business_payment_approval_request } from '@/network/finance/BusinessPayment.js'  //业务付款
import { vacation_sel_approval_request,vacation_approval_request } from '@/network/hrm/vacation.js' //休假
import { go_out_sel_approval_request,go_out_approval_request } from '@/network/hrm/GoOut.js' //外出
import { over_time_sel_approval_request,over_time_approval_request } from '@/network/hrm/OverTime.js' //加班
import { supplement_clock_in_sel_approval_request,supplement_clock_in_approval_request } from '@/network/hrm/SupplementClockIn.js' //补签
import { contract_sel_approval_request,contract_approval_request } from '@/network/finance/Contract.js' //合同
import { cooperative_enterprise_sel_approval_request,cooperative_enterprise_approval_request } from '@/network/business/CooperativeEnterprise.js' //合作企业
import { currency_sel_approval_request,currency_approval_request } from '@/network/finance/Currency.js' //印章使用申请
import { car_use_sel_approval_request,car_use_approval_request } from '@/network/hrm/CarUse.js' //公务车使用申请

import { avatar_list_request } from '@/network/list.js' //头像

export default {
    name:'ApprovalDialogComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            my_nav_bar_name: '审批详情',
            type: 0,
            id: 0,
            ApprovalAuth: false,
            data: [],
            active: 999,
            form: {
                opinion: '',
                note: ''
            },
            AllAvatar: [],
            show: false,
            form: {
                opinion: '',
                note: '',
                other: []
            },
        }
    },
    computed:{
        withdraw_staff_head_portrait() {
            return this.$store.state.baseURL + this.data[0]['head_portrait']
        }
    },
    methods:{
        get_all_avatar() {
            avatar_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllAvatar = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_sel_approval() {
            if(this.type === 0) {   //采购
                this.SelRequest = purchase_sel_approval_request
                this.ApprovalRequest = purchase_approval_request
            } else if(this.type === 1) {   //招待
                this.SelRequest = entertain_sel_approval_request
                this.ApprovalRequest = entertain_approval_request
            } else if(this.type === 6) {   //差旅
                this.SelRequest = travel_sel_approval_request
                this.ApprovalRequest = travel_approval_request
            } else if(this.type === 2) {   //备用金
                this.SelRequest = petty_sel_approval_request
                this.ApprovalRequest = petty_approval_request
            } else if(this.type === 7) {   //报销
                this.SelRequest = reimbursement_sel_approval_request
                this.ApprovalRequest = reimbursement_approval_request
            } else if(this.type === 10) {   //业务付款
                this.SelRequest = business_payment_sel_approval_request
                this.ApprovalRequest = business_payment_approval_request
            } else if(this.type === 8) {   //休假
                this.SelRequest = vacation_sel_approval_request
                this.ApprovalRequest = vacation_approval_request
            } else if(this.type === 11) {   //外出
                this.SelRequest = go_out_sel_approval_request
                this.ApprovalRequest = go_out_approval_request
            } else if(this.type === 12) {   //加班
                this.SelRequest = over_time_sel_approval_request
                this.ApprovalRequest = over_time_approval_request
            } else if(this.type === 13) {   //补签
                this.SelRequest = supplement_clock_in_sel_approval_request
                this.ApprovalRequest = supplement_clock_in_approval_request
            } else if(this.type === 14) {   //合同
                this.SelRequest = contract_sel_approval_request
                this.ApprovalRequest = contract_approval_request
            } else if(this.type === 15) {   //合作企业
                this.SelRequest = cooperative_enterprise_sel_approval_request
                this.ApprovalRequest = cooperative_enterprise_approval_request
            } else if(this.type === 17) {   //印章使用申请
                this.SelRequest = currency_sel_approval_request
                this.ApprovalRequest = currency_approval_request
            } else if(this.type === 19) {   //公务车使用申请
                this.SelRequest = car_use_sel_approval_request
                this.ApprovalRequest = car_use_approval_request
            }

            this.$store.commit('true_loading')
                this.SelRequest(this.id)
                    .then((s) => {
                        if (s.status === 0) {
                            this.data = s.result
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => {
                        this.$store.commit('false_loading')
                    })
        },
        col_content_class(item) {
            if(item.opinion) {
                return item.opinion === '同意' ? 'col_content_yes' : 'col_content_no'
            }
            if(item.staff_id === -1) {
                return 'col_content_withdraw'
            }
            return 'col_content_yes'
        },
        close_dialog() {
            this.form = {
                opinion: '',
                note: '',
                other: []
            }
        },
        submitApproval() {
            this.$store.commit('true_loading')
            this.form.id = this.id
            this.ApprovalRequest(this.form)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('已审批 ')
                        this.$router.go(-1);//返回上一层
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => {
                    this.$store.commit('false_loading')
                })
        },
        update_img(arr) {
            this.form.other = arr
        },
    },
    filters:{
        zidongtongguo(v) {
            if(v.opinion) {
                if(v.opinion === '同意') {
                    return '通过'
                } else if(v.opinion === '拒绝') {
                    return '驳回'
                }
            }

            if(v.staff_id === -1) {
                return '撤回'
            }

            return '自动通过'
        },
        withdraw_staff_name(v) {
            return v[0]['start_staff_name']
        }
    },
    props:{},
    created(){
        this.get_all_avatar()
        this.type = this.$route.query.type
        this.id = this.$route.query.id
        this.ApprovalAuth = this.$route.query.ApprovalAuth
        if(this.id) {
            this.get_sel_approval()
        }
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        UploaderComponent
    },
    watch:{
        data(newVal) {
            let status = true
            newVal.forEach((v,i) => {
                if((i > 0) && (v.time === null) && status) {
                    this.active = i
                    status = false
                }

                this.AllAvatar.forEach((vv,ii) => {
                    if((v.start_staff_id === vv.id) || (v.staff_id === vv.id)) {
                        v.head_portrait = vv.head_portrait
                    }
                })
            })
        }
    }
}
</script>

<style scoped>
.van-cell-group {
    margin-top: 0.3rem;
}
.col_content_yes {
    color: #00BB00;
    font-weight:bold
}
.col_content_no {
    color: #CE0000;
    font-weight: bold
}
.col_content_withdraw {
    color: #B15BFF;
    font-weight: bold
}
.sub_button {
    margin: 0.5rem;
}
</style>